import * as statusMapper from "@/service/error_request_mapper.js";

export class LegalStructureEventsService {
  constructor(api) {
    this.api = api;
  }

  /**
   * retourne la liste des événements de la structure juridique.
   * @param {*} legalStructureId
   */
  async getView(legalStructureId) {
    return this.api
      .getView(legalStructureId)
      .then((data) => {
        data.sort(function(a, b) {
          return a.date.localeCompare(b.date);
        });

        //conversion de la date yyyymmdd en date js
        data.forEach((e) => {
          let d =
            e.date.substring(0, 4) +
            "-" +
            e.date.substring(4, 6) +
            "-" +
            e.date.substring(6, 8);

          let dt = new Date(d + "T00:00");
          e.dt = dt;
          e.date = dt.toLocaleDateString();
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(legalStructureId);
        converter.convert(error);
      });
  }

  /** Ajouter un événement à une structure juridique. La date doit être au format YYYYMMDD  */
  async addEvent(legalStructureId, eventTypeId, date, docLink, description) {
    return this.api
      .addEvent(legalStructureId, eventTypeId, date, docLink, description)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(legalStructureId);
        converter.convert(error);
      });
  }

  /** Ajouter un événement à une structure juridique */
  async removeEvent(legalStructureId, eventId) {
    return this.api.removeEvent(legalStructureId, eventId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(eventId);
      converter.convert(error);
    });
  }

  async updateEvent(
    legalStructureId,
    eventId,
    eventTypeId,
    date,
    docLink,
    description
  ) {
    return this.api
      .updateEvent(
        legalStructureId,
        eventId,
        eventTypeId,
        date,
        docLink,
        description
      )
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(eventId);
        converter.convert(error);
      });
  }
}
