import * as statusMapper from "@/service/error_request_mapper.js";

export class LegalStructureExternalContractService {
  constructor(api) {
    this.api = api;
  }

  /**
   * retourne la liste des contrats externes de la structure juridique.
   * @param {*} legalStructureId
   */
  async getView(legalStructureId) {
    return this.api
      .getView(legalStructureId)
      .then((data) => {
        data.sort(function(a, b) {
          return a.contractType.label.localeCompare(b.contractType.label);
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(legalStructureId);
        converter.convert(error);
      });
  }

  /** Ajouter un contrat à une structure juridique */
  async addContract(legalStructureId, contractTypeId, contractContactId) {
    return this.api
      .addContract(legalStructureId, contractTypeId, contractContactId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(legalStructureId);
        converter.convert(error);
      });
  }

  /** Ajouter un contrat à une structure juridique */
  async removeContract(legalStructureId, itemId) {
    return this.api.removeContract(legalStructureId, itemId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(itemId);
      converter.convert(error);
    });
  }

  async updateContract(
    legalStructureId,
    contractId,
    contractTypeId,
    contractContactId
  ) {
    return this.api
      .updateContract(
        legalStructureId,
        contractId,
        contractTypeId,
        contractContactId
      )
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(contractId);
        converter.convert(error);
      });
  }
}
