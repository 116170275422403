import * as statusMapper from "@/service/error_request_mapper.js";

export class LegalStructureStockholderService {
  constructor(api) {
    this.api = api;
  }

  /**
   * retourne la liste des actionnaires de la structure juridique. Les actionnaires sont directement nommés. Pas
   * besoin de faire des jointures à la main avec les relations
   * @param {*} legalStructureId
   */
  async getViewByLegalStructure(legalStructureId) {
    return this.api
      .getViewByLegalStructure(legalStructureId)
      .then((data) => {
        data.sort(function(a, b) {
          return -(a.percent - b.percent);
        });

        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(legalStructureId);
        converter.convert(error);
      });
  }

  /**Ajouter un actionnaire sur la structure juridique */
  async addStockholderToStructure(legalStructureId, stockholderId, percent) {
    return this.api
      .addStockholderToStructure(legalStructureId, stockholderId, percent)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(legalStructureId);
        converter.convert(error);
      });
  }

  /**Supprimer un actionnaire sur la structure juridique. itemId est l'identifiant de la relation : structure -> actionnaire */
  async removeStockholderFromStructure(legalStructureId, itemId) {
    return this.api
      .removeStockholderFromStructure(legalStructureId, itemId)
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(itemId);
        converter.convert(error);
      });
  }

  async updateStockholderForStructure(
    legalStructureId,
    itemId,
    stockholderId,
    percent
  ) {
    return this.api
      .updateStockholderForStructure(
        legalStructureId,
        itemId,
        stockholderId,
        percent
      )
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(itemId);
        converter.convert(error);
      });
  }
}
